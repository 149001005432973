import Utils from '../../utility'
export const onSubmit = (values: any, navigate: any) => {
  return (dispatch: any) => {
    const { userId, pin } = values
    const dataToSend = {
      username: userId,
      password: pin,
    }
    Utils.api.postApiCall(
      Utils.endPoints.login,
      dataToSend,
      (respData: any) => {
        //, 'SA'
        if (['B'].includes(respData?.data?.userType)) {
          localStorage.setItem('accessToken', respData?.data?.token)
          localStorage.setItem('email', respData?.data?.email)
          localStorage.setItem('id', respData?.data?.id)
          localStorage.setItem('username', respData?.data?.userName)
          localStorage.setItem('userType', respData?.data?.userType)
          localStorage.setItem('refreshToken', respData?.data?.refreshToken)
          dispatch({
            type: Utils.ActionName.COMMON,
            payload: {
              userId: respData?.data?.id,
              // storeId: '',
              userType: respData?.data?.userType,
              username: respData?.data?.userName,
              email: respData?.data?.email,
              refreshTokenData: respData?.data?.refreshToken
            },
          })
          // dispatch(onRefreshToken(respData?.data?.refreshToken))
          navigate('/billing')
        } else {
          Utils.showAlert(2, 'Permission denied')
        }
      },
      (error: any) => {
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Login Add Error')
        } else {
          Utils.showAlert(2, 'Login Add Error: ' + data?.message)
        }
      },
    )
  }
}

export const onRefreshToken = (token: any) => {
  return (dispatch: any) => {
    const dataToSend = {
      refreshToken: token
    }
    Utils.api.postApiCall(
      Utils.endPoints.refreshLogin,
      dataToSend,
      (respData: any) => {
          localStorage.setItem('accessToken', respData?.data?.token)
          localStorage.setItem('email', respData?.data?.email)
          localStorage.setItem('id', respData?.data?.id)
          localStorage.setItem('username', respData?.data?.userName)
          localStorage.setItem('userType', respData?.data?.userType)
          localStorage.setItem('refreshToken', "Bearer" + " " + respData?.data?.refreshToken)
          dispatch({
            type: Utils.ActionName.COMMON,
            payload: {
              userId: respData?.data?.id,
              userType: respData?.data?.userType,
              username: respData?.data?.userName,
              email: respData?.data?.email,
              refreshTokenData: respData?.data?.refreshToken
            },
          })
      },
      (error: any) => {
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Refresh Token Error')
        } else {
          Utils.showAlert(2, 'Refresh Token Error: ' + data?.message)
        }
      },
    )
  }
}

// export const logoutRefreshToken = (token: any,navigate:any) => {
//   if(token.includes("Bearer")){
//     navigate("/")
//     localStorage.clear()
//   }
//   return (dispatch: any) => {
//     const dataToSend = {
//       refreshToken: token.includes("Bearer")?token.split(" ")[1] : token
//     }
//     Utils.api.postApiCall(
//       Utils.endPoints.logout,
//       dataToSend,
//       (respData: any) => {
//          navigate("/")
//          localStorage.clear()
//       },
//       (error: any) => {
//         let { data } = error
//         if (data?.message === '' || !data) {
//           Utils.showAlert(2, 'Logout Error')
//         } else {
//           Utils.showAlert(2, 'Logout Error: ' + data?.message)
//         }
//       },
//     )
//   }
// }

export const logoutRefreshToken = (navigate) => {
  if (localStorage.getItem("refreshToken") == null) {
    localStorage.clear();
    navigate("/");
    return;
  }
  return (dispatch) => {
    const dataToSend = {
      refreshToken: localStorage.getItem("refreshToken"),
    };

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.logout,
      dataToSend,
      (respData) => {
        localStorage.clear();
        navigate("/");
      },
      (error) => {
        let { data, status } = error;
        if (status === 401) {
          localStorage.clear();
          navigate("/");
        } else {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message);
        Utils.showAlert(
          2,
          `${Utils.endPoints.login} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }}
    );
  };
};

export const getBillers = () => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.biller,
      '',
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.BILLER,
          payload: {
            loginData: respData.data,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Billers Get Error')
        } else {
          Utils.showAlert(2, 'Billers Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const getBillerId = (id: any, navigate: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.biller,
      `/${id}`,
      (respData: any) => {
        dispatch(getRoyalityData(respData.data[0].store?.id))
        dispatch({
          type: Utils.ActionName.BILLER,
          payload: {
            billerIdData: respData.data[0].store?.id,
            billerName: respData.data[0].biller?.name,
            cashPending: respData.data[0].store?.cashPending,
          },
        })
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            storeId: respData.data[0].store?.id,
          },
        })
        localStorage.setItem('storeId', respData.data[0].store?.id)
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Biller By Id Get Error')
          dispatch(logoutRefreshToken(navigate))
        } else {
          Utils.showAlert(2, 'Biller By Id Get Error: ' + data?.message)
          dispatch(logoutRefreshToken(navigate))
        }
      },
    )
  }
}

export const getRoyalityData = (storeID:any,navigate?:any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.store,
      `royalty?store=${storeID}&status=pending&order=DESC&offset=1&take=10&sort=royaltyPending`,
      (respData: any) => {
        if (navigate) navigate("/billing");
        dispatch({
          type: Utils.ActionName.BILLER,
          payload: {
            royalityData: respData.data,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Royality Get Error')
        } else {
          Utils.showAlert(2, 'Royality Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const creatOrderForPayment = (values, callback) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      invoiceNumber: "inoice_20",
      amount: `${values?.amount}`,
      notes: "strindsfsg",
      storeId: values?.storeId.toString(),
    };

    Utils.api.postApiCall(
      Utils.endPoints.store + "razorPay",
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        callback(respData?.data);
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createStore} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
      }
    );
  };
};