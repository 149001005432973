import Utils from '../../utility'

const initialState = {
    loginData: [],
    billerIdData: "",
    billerName: "",
    royalityData:[]
}
export const loginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${Utils.ActionName.BILLER}`:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return { ...state }
    }
}
